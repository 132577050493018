import { Link } from "gatsby";
import React from "react";
import closeWhiteSvg from "../../../assets/Images/close_white.svg";
import DigiryteLogoMobile from "../../../assets/Images/Homepage/Digiryte-logo-mobile.svg";
import "./DrawerToggleButton.scss";

const drawerToggleButton = (props) => (
  <React.Fragment>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Link to="/">
        <img
          className={props.close ? "mobile-sidebar-logo" : ""}
          src={DigiryteLogoMobile}
          alt="Digiryte"
        />
      </Link>
      {!props.close ? (
        <button className="toggle-button" onClick={props.click}>
          <div className="toggle-button__line" />
          <div className="toggle-button__line" />
          <div className="toggle-button__line" />
        </button>
      ) : (
        <img
          role="presentation"
          className="mobile-sidebar-close-icon"
          onKeyDown={props.onclose}
          onClick={props.onclose}
          src={closeWhiteSvg}
          alt="Close"
        />
      )}
    </div>
  </React.Fragment>
);

export default drawerToggleButton;
