import React, { createContext, useContext, useState } from "react";

const ContactModalContext = createContext();

export const ContactModalProvider = ({ children }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <ContactModalContext.Provider value={{ openModal, setOpenModal }}>
      {children}
    </ContactModalContext.Provider>
  );
};

export const useContactModal = () => useContext(ContactModalContext);
