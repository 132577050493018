import { Button, Tooltip } from "@material-ui/core";
import { Link } from "gatsby";
import React from "react";
import "../../themes/fonts/font.scss";
import "./Button.scss";

const Btn = (props) => {
  return (
    <div className={`${props.btnWrapClassName}`}>
      {props.routepath || props.view === "show" ? (
        props.tooltip === "yes" ? (
          <Link to={props.routepath} className="services-link">
            <Tooltip title={props.tip} placement="bottom">
              <Button
                style={{ fontFamily: "Helvetica Neue", boxShadow: "none" }}
                className={props.customClassName}
                variant={props.variant}
                color={props.color}
                size={props.size}
              >
                {props.label}
                {props.icon}
              </Button>
            </Tooltip>
          </Link>
        ) : (
          <Link to={props.routepath} className="services-link">
            <Button
              style={{ fontFamily: "Helvetica Neue", boxShadow: "none" }}
              className={props.customClassName}
              variant={props.variant}
              color={props.color}
              // size={props.size}
              size="small"
            >
              {props.label}
              {props.icon}
            </Button>
          </Link>
        )
      ) : (
        <Button
          style={{ fontFamily: "Helvetica Neue", boxShadow: "none" }}
          className={props.customClassName}
          variant={props.variant}
          color={props.color}
          onClick={props.onClick ? props.onClick : () => {}}
          size={props.size}
        >
          {props.label}
          {props.icon}
        </Button>
      )}
    </div>
  );
};

export default Btn;
