import axios from "axios";
import { BASE_API_URL, TOKEN } from "./constants";

export const request = function (options) {
  const client = axios.create({
    baseURL: BASE_API_URL,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN}`,
      // 'Access-Control-Allow-Origin': '*',
    },
  });

  const onSuccess = function (response) {
    return response.data;
  };

  const onError = function (error) {
    return Promise.reject(
      error.response
        ? error.response.data
        : { message: "something went wrong", success: false } ||
            error.message ||
            error.error
    );
  };

  return client(options).then(onSuccess).catch(onError);
};

export default request;
