import request from "../../shared/api_client";

function getData(url, params) {
  return request({
    url: url,
    method: "GET",
  });
}

function postData(url, data, params) {
  return request({
    url: url,
    method: "POST",
    data: data,
    params: params ? params : {},
  });
}

const DataService = {
  getData,
  postData,
};

export default DataService;
