import React, { Component } from "react";
import Backdrop from "./Backdrop/Backdrop";
import SideDrawer from "./SideDrawer/SideDrawer";
import Toolbar from "./Toolbar/Toolbar";
import AnnouncementBar from "./Announcement";

class Navbar extends Component {
  state = {
    sideDrawerOpen: false,
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
    }
    return (
      <div style={{ height: "100%", zIndex: 4, position: "relative" }}>
        {!this.props.isLandingPage && <AnnouncementBar />}
        <Toolbar
          drawerClickHandler={this.drawerToggleClickHandler}
          siteTitle={this.props.siteTitle}
          isLandingPage={this.props?.isLandingPage}
        />
        <SideDrawer
          show={this.state.sideDrawerOpen}
          close={this.backdropClickHandler}
        />
        {backdrop}
      </div>
    );
  }
}

export default Navbar;
