export const TOKEN =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMTZlNGRlNmZlZTEyM2JjMTRmNGNiMGM4Y2YxN2M0YWJlYzU5Y2NjZDQ2ZGM1YmM4NGY4NGQ1MmIyMDAyOWE4MzI5Y2Y3NGNjNTNkMjQ2NGMiLCJpYXQiOjE1ODM3NDcyMTcsIm5iZiI6MTU4Mzc0NzIxNywiZXhwIjoxNjE1MjgzMjE3LCJzdWIiOiI2Iiwic2NvcGVzIjpbXX0.Tk3bv01vvJGwG2KYmfWo_2bZIUUMW_A3UoCe_QT6ZjyV17GHOti1nE5aKWPmGOS1NAffBkAzHd9Z0K_RAMzltUHpNHw4MRveaGTVkjZgfRUjcbabm0ewrkLAVhKAMuh7_5EGcgpOcS_AIDYjxSSjkLJTungp5DTaabp2u4idUW7oA7IV4aE9NLfXmiiwcqKZNrcgNnzlsjmYNt4k6mR1U5-qm9RK-wF6vUo3LpFT3x9fMMsFoYZZlXXSmn_c3skvCcoGotQqpuJYc_R8C8Hb90r28RE7KMcXx9aGcf-UTzen2nrxt305pDFR3uFuCNoj21n5poT6GEU2HQkvYs2vdafIA_hhXLvffOGqEc0UusYQ-LEaCdrJmaib7NtAxYyvw4iC1CP1ruyXpLj7lX6aSPuBIYRMvsSGDWUoivT6GkAXFBaAzKTh9tWEgPVaMMDkKswGq7A75YwrBrOxF_pvId46zUxW35w8rzSGgwFU7zwdkrZl21WA6QyX8z2yS5CPnKR6U47r6t0b_heaoQVqMoTAf4r7T_EHDNYLb0DrM0j7sQw6p0k5vUaWlxWyuJLVFr59yjkWvYvgkvoQ7cngoNV_cIOIGwrEIts7KUquJSVod3NReb_EFgzaX47GufnkBZ3O89-WyYb1iv7AJyeDOCttA5MtvnAnOYJVdmAz-2Q";

// DEVELOPMENT
// export const BASE_SITE_URL = "http://development.d19qyyv7hinsa9.amplifyapp.com";
// export const BASE_API_URL = "https://stagingapi.digiryte.co.uk";

// PRODUCTION
export const BASE_SITE_URL = "https://www.digiryte.com";
export const BASE_API_URL = "https://resources.digiryte.com";
