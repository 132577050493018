import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import InputComponent from "../input";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import * as Yup from "yup";
import DataService from "../../services/api/data_services";
import { navigate } from "gatsby";

function FormWrap({ onlyForm, pageUrl = "" }) {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarStatus, setSnackbarStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [location, setLocation] = useState("");

  const urlPathname = typeof window !== "undefined" ? window.location.href : "";
  useEffect(() => {
    DataService.getData(`https://ipapi.co/json/`).then((res) =>
      setLocation(res.city)
    );
  }, []);
  const validateSchema = Yup.object().shape({
    firstName: Yup.string().required("This field is required"),
    lastName: Yup.string().required("This field is required"),

    email: Yup.string()
      .email("Please enter a valid email")
      .required("This field is required"),
    phone: Yup.string()
      .required("This field is required")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      ),
    organization: Yup.string().required("This field is required"),
    jobTitle: Yup.string().required("This field is required"),
    message: Yup.string().required("This field is required"),
  });
  const handleSubmit = (formData) => {
    // setapproval(false);
    setIsLoading(true);
    DataService.postData(
      `admin/api/sendmail`,
      { ...formData, location: location },
      {
        type: "contactus_new",
      }
    )
      .then((response) => {
        if (typeof window !== `undefined`) {
          navigate(`/thank-you-contact${pageUrl}`);
        }
      })
      .catch((error) => {
        setSnackbarStatus(true);
        setSnackbarMessage(error.message);
        setTimeout(() => {
          setSnackbarStatus(false);
          setSnackbarMessage("");
        }, 10000);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div className={`form-right-wrap ${onlyForm ? "only-form-container" : ""}`}>
      <h2 className="title-header">
        <span className="red-line3letter red-3letter-mbl">Let'</span>s converse
      </h2>
      <br />
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          jobTitle: "",
          organization: "",
          message: "",
          opt_in: false,
          privacy_policy: true,
          location: location,
          source: urlPathname,
        }}
        validationSchema={validateSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ values, touched, errors, handleChange, handleSubmit }) => {
          return (
            <>
              <div className="mt-20 flex-row">
                <InputComponent
                  value={values.firstName}
                  name="firstName"
                  onChange={handleChange}
                  label="First Name"
                  is_error={errors.firstName && touched.firstName}
                  error_message={errors.firstName}
                  wrapClassName="w-half"
                />
                <InputComponent
                  value={values.lastName}
                  name="lastName"
                  onChange={handleChange}
                  label="Last Name"
                  is_error={errors.lastName && touched.lastName}
                  error_message={errors.lastName}
                  wrapClassName="w-half"
                />
              </div>
              <div className="mt-20">
                <InputComponent
                  value={values.email}
                  name="email"
                  onChange={handleChange}
                  label="Business Email"
                  is_error={errors.email && touched.email}
                  error_message={errors.email}
                  wrapClassName="w-half"
                />
              </div>
              <div className="mt-20 flex-row">
                <InputComponent
                  value={values.phone}
                  name="phone"
                  onChange={handleChange}
                  label="Phone Number"
                  is_error={errors.phone && touched.phone}
                  error_message={errors.phone}
                  wrapClassName="w-half"
                />
                <InputComponent
                  value={values.jobTitle}
                  name="jobTitle"
                  onChange={handleChange}
                  label="Job Title"
                  is_error={errors.jobTitle && touched.jobTitle}
                  error_message={errors.jobTitle}
                  wrapClassName="w-half"
                />
              </div>

              <div className="mt-20">
                <InputComponent
                  value={values.organization}
                  name="organization"
                  onChange={handleChange}
                  label="Company Name"
                  is_error={errors.organization && touched.organization}
                  error_message={errors.organization}
                />
              </div>
              <div className="mt-20">
                <InputComponent
                  value={values.message}
                  name="message"
                  onChange={handleChange}
                  label="Message"
                  placeholder="Write your message.."
                  is_error={errors.message && touched.message}
                  error_message={errors.message}
                  type={"textarea"}
                  className="textarea"
                />
              </div>
              <button
                className="submit-btn mt-20"
                type="submit"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    Sending...{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 24 24"
                    >
                      <g
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeWidth={2}
                      >
                        <path
                          strokeDasharray={60}
                          strokeDashoffset={60}
                          strokeOpacity={0.3}
                          d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3Z"
                        >
                          <animate
                            fill="freeze"
                            attributeName="stroke-dashoffset"
                            dur="1.3s"
                            values="60;0"
                          ></animate>
                        </path>
                        <path
                          strokeDasharray={15}
                          strokeDashoffset={15}
                          d="M12 3C16.9706 3 21 7.02944 21 12"
                        >
                          <animate
                            fill="freeze"
                            attributeName="stroke-dashoffset"
                            dur="0.3s"
                            values="15;0"
                          ></animate>
                          <animateTransform
                            attributeName="transform"
                            dur="1.5s"
                            repeatCount="indefinite"
                            type="rotate"
                            values="0 12 12;360 12 12"
                          ></animateTransform>
                        </path>
                      </g>
                    </svg>
                  </>
                ) : (
                  <>
                    {onlyForm ? (
                      <div className="custom-form-width-btn">
                        Send Now
                        <span className="btn-icon">
                          <KeyboardArrowRightOutlinedIcon />
                        </span>
                      </div>
                    ) : (
                      <>
                        Send Now
                        <span className="btn-icon">
                          <KeyboardArrowRightOutlinedIcon />
                        </span>
                      </>
                    )}
                  </>
                )}
              </button>
              {snackbarStatus && (
                <div className="error_message">
                  <div className="error_content">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      viewBox="0 0 36 36"
                    >
                      <path
                        fill="currentColor"
                        d="M18 6a12 12 0 1 0 12 12A12 12 0 0 0 18 6m-1.49 6a1.49 1.49 0 0 1 3 0v6.89a1.49 1.49 0 1 1-3 0ZM18 25.5a1.72 1.72 0 1 1 1.72-1.72A1.72 1.72 0 0 1 18 25.5"
                        className="clr-i-solid clr-i-solid-path-1"
                      ></path>
                      <path fill="none" d="M0 0h36v36H0z"></path>
                    </svg>
                    <span>
                      <span style={{ fontWeight: 700 }}>Error:</span>
                      <span> {snackbarMessage}</span>
                    </span>
                  </div>
                  <div
                    onClick={() => {
                      setSnackbarStatus(false);
                      setSnackbarMessage("");
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1rem"
                      height="1rem"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="m6.4 18.308l-.708-.708l5.6-5.6l-5.6-5.6l.708-.708l5.6 5.6l5.6-5.6l.708.708l-5.6 5.6l5.6 5.6l-.708.708l-5.6-5.6z"
                      ></path>
                    </svg>
                  </div>
                </div>
              )}
            </>
          );
        }}
      </Formik>
      <div className="list-item-content mt-20">
        {["100% confidential", "We sign NDAs"].map((item, index) => (
          <div key={index} className="list-item-child">
            <div className="">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  y="0.26825"
                  width="24"
                  height="24"
                  rx="12"
                  fill="#E89F38"
                />
                <path
                  d="M18 5.86823L17.8364 5.75312L17.8363 5.75327L17.8359 5.75391L17.834 5.75655L17.8265 5.76719L17.7965 5.80952C17.77 5.84695 17.7303 5.9026 17.6783 5.97528C17.5743 6.12065 17.4205 6.33415 17.2221 6.60627C16.8253 7.15052 16.2497 7.92922 15.5354 8.86637C14.1064 10.7409 12.1229 13.2483 9.90367 15.7811C9.37776 16.381 8.90051 16.6 8.49311 16.6261C8.08233 16.6524 7.70105 16.4853 7.36422 16.2294C7.02742 15.9736 6.75049 15.6402 6.55592 15.3658C6.45924 15.2295 6.38428 15.1097 6.33373 15.0244C6.30847 14.9818 6.28938 14.948 6.27678 14.9251L6.26281 14.8994L6.25956 14.8932L6.25889 14.8919L6.25886 14.8919C6.25884 14.8918 6.25882 14.8918 6.25881 14.8918L6.2588 14.8918C6.2192 14.8152 6.13499 14.7726 6.04984 14.7861C5.96465 14.7996 5.89771 14.8662 5.88377 14.9513C5.69619 16.0971 5.8337 16.9783 6.19672 17.6173C6.56149 18.2594 7.14102 18.634 7.78759 18.7867C9.06416 19.0883 10.625 18.5323 11.4303 17.46L11.4304 17.46C12.5341 15.9903 14.2229 13.1104 15.6303 10.6091C16.3354 9.3562 16.9719 8.19455 17.4321 7.34617C17.6622 6.92195 17.8483 6.576 17.9769 6.33607C18.0412 6.2161 18.0911 6.12263 18.125 6.05912L18.1635 5.98677L18.1733 5.96827L18.1758 5.96357L18.1764 5.96238L18.1766 5.96208C18.1766 5.96201 18.1767 5.96197 18 5.86823ZM18 5.86823L17.8365 5.75308C17.8973 5.66675 18.0147 5.64254 18.1047 5.69779C18.1946 5.75304 18.2262 5.86871 18.1767 5.96197L18 5.86823Z"
                  fill="white"
                  stroke="white"
                  strokeWidth="0.4"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <p>{item}</p>
          </div>
        ))}
      </div>
      {!onlyForm && (
        <p>
          To give you the content requested, we need to store and process your
          personal data. For information on how to unsubscribe, as well as our
          privacy practices and commitment to protecting your privacy, please
          review our{" "}
          <a className="atagclass" target="_blank" href="/privacy-policy">
            Privacy Policy.
          </a>
        </p>
      )}
    </div>
  );
}

export default FormWrap;
