import React from "react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import "./style.scss";

function AnnouncementBar() {
  return (
    <div className="announce_wrapper">
      <div className="body-container announce_container">
        <div className="announce_container no-padding">
          <div className="announce_inner_container">
            <span className="announce_tag md-hidden">NEW</span>
            <p className="announce_label md-hidden">
              Find out how digiryte pods can crush IT delivery challenges
            </p>
          </div>
          <a
            className="announce_btn md-hidden"
            href="/white-paper/decoding-how-digiryte-pods-can-crush-it-delivery-challenges/"
          >
            Download now <FileDownloadOutlinedIcon />
          </a>
        </div>
        <div className="announce_tel_wrap">
          <span className="">Call us at </span>{" "}
          <a className="announce_tel" href="tel:+44 (0) 161 706 7983">
            +44 (0) 161 706 7983
          </a>
        </div>
      </div>
    </div>
  );
}

export default AnnouncementBar;
