import * as React from "react";
import Modal from "@mui/material/Modal";
import "./style.scss";
import { useContactModal } from "./contactModalContext";

export default function ModalComponent({ children }) {
  const { openModal, setOpenModal } = useContactModal(); // Use context
  return (
    <div>
      <Modal open={openModal} onClose={() => setOpenModal(false)} hideBackdrop>
        <>
          <div className="modal-wrap">
            <div className="modal-container">{children}</div>
          </div>
          <div className="modal-backdrop"></div>
        </>
      </Modal>
    </div>
  );
}
