import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Divider from "@material-ui/core/Divider";
import {withRouter} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    typography: {
        padding: theme.spacing(2),
    },
    searchResultsWrap : {
        width: '810px',
        minHeight:'100px',
        padding:'2%',
        margin:'auto',
        maxHeight:'80vh'
    },
    section: {
      padding:theme.spacing(2),
      minHeight:'75px'
    },
    results: {
        padding:'10px 30px',
        display:'flex',
        justifyContent:'space-between',
        cursor:'pointer',
        '& .name':{
            color:'#2f2f2f'
        },
        '& .country': {
            color: '#919191',
        },
        '&:hover':{
            background:'rgb(248, 187, 208)'
        }
    },
    noRes:{
        padding:'10px 30px',
    }
}));

function SearchResultsPopover(props) {
    const classes = useStyles();
    const {id,anchorEl,handleClose,searchResult,loading} = props;
    const open = Boolean(anchorEl);
    const companies = searchResult ? searchResult.companies : [];
    const tradesman = searchResult ? searchResult.tradesmen : [];
    const consumer = searchResult ? searchResult.users : [];
    const history = props.history;
    return (
        <React.Fragment>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            style={{marginTop:'25px',marginLeft:'-45px'}}
            
        >
            <div className={classes.searchResultsWrap}>
                <div className={classes.section}>
                    <Typography component="h3" variant="subtitle1">Industries:</Typography>
                    {companies && companies.map((res,i)=>{
                        return (
                            <React.Fragment key={i}>
                                <div key={i} className={classes.results} >
                                    <span className={"name"}>{res.name}</span><span className={"country"}>{res.country_code}</span>
                                </div>
                                { i !== companies.length - 1 && <Divider variant={"middle"} />}
                            </React.Fragment>
                        )
                    })}
                    {companies && companies.length < 1 && <Typography className={classes.noRes} variant="subtitle2">No results found</Typography>}
                </div>
                <Divider variant={"fullWidth"} />
                <div className={classes.section}>
                    <Typography component="h3" variant="subtitle1">Services:</Typography>
                    {tradesman && tradesman.map((res,i)=>{
                        return (
                            <React.Fragment key={i}>
                                <div className={classes.results} >
                                    <span className={"name"}>{`${res.first_name} ${res.last_name}`}</span><span className={"country"}>{res.country_code}</span>
                                </div>
                                { i !== tradesman.length - 1 && <Divider variant={"middle"} />}
                            </React.Fragment>

                        )
                    })}
                    {tradesman && tradesman.length < 1 && <Typography className={classes.noRes} variant="subtitle2">No results found</Typography>}
                </div>
                <Divider variant={"fullWidth"} />
                <div className={classes.section}>
                    <Typography component="h3" variant="subtitle1">Articles:</Typography>
                    {consumer && consumer.map((res,i)=>{
                        return (
                            <React.Fragment key={i}>
                                <div  className={classes.results} >
                                    <span className={"name"}>{`${res.first_name} ${res.last_name}`}</span><span className={"country"}>{res.country_code}</span>
                                </div>
                                { i !== consumer.length - 1  && <Divider variant={"middle"} />}
                            </React.Fragment>

                        )
                    })}
                    {consumer && consumer.length < 1 && <Typography className={classes.noRes} variant="subtitle2">No results found</Typography>}
                </div>
                <Divider variant={"fullWidth"} />
                <div className={classes.section}>
                    <Typography component="h3" variant="subtitle1">Client Story:</Typography>
                    {consumer && consumer.map((res,i)=>{
                        return (
                            <React.Fragment key={i}>
                                <div  className={classes.results} >
                                    <span className={"name"}>{`${res.first_name} ${res.last_name}`}</span><span className={"country"}>{res.country_code}</span>
                                </div>
                                { i !== consumer.length - 1  && <Divider variant={"middle"} />}
                            </React.Fragment>

                        )
                    })}
                    {consumer && consumer.length < 1 && <Typography className={classes.noRes} variant="subtitle2">No results found</Typography>}
                </div>
                <Divider variant={"fullWidth"} />
                <div className={classes.section}>
                    <Typography component="h3" variant="subtitle1">White paper:</Typography>
                    {consumer && consumer.map((res,i)=>{
                        return (
                            <React.Fragment key={i}>
                                <div  className={classes.results} >
                                    <span className={"name"}>{`${res.first_name} ${res.last_name}`}</span><span className={"country"}>{res.country_code}</span>
                                </div>
                                { i !== consumer.length - 1  && <Divider variant={"middle"} />}
                            </React.Fragment>

                        )
                    })}
                    {consumer && consumer.length < 1 && <Typography className={classes.noRes} variant="subtitle2">No results found</Typography>}
                </div>
            </div>
        </Popover>
    </React.Fragment>

    );
}


export default SearchResultsPopover;
