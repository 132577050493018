// src/VerticalProgressBar.js
import React, { useState, useEffect } from "react";
import "./VerticalProgressBar.css";

const VerticalProgressBar = () => {
  const [step, setStep] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setStep((prevStep) => (prevStep % 3) + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const getFilledLineHeight = () => {
    switch (step) {
      case 1:
        return "0%";
      case 2:
        return "50%";
      case 3:
        return "100%";
      default:
        return "0%";
    }
  };
  const steps = [0, 1, 2];
  return (
    <div className="vertical-progress-bar">
      <div className="line"></div>
      <div
        className="filled-line"
        style={{ height: getFilledLineHeight() }}
      ></div>
      {steps.map((s, index) => (
        <div key={index} className={`step ${step >= s ? "filled" : ""}`}>
          {s + 1}
        </div>
      ))}
    </div>
  );
};

export default VerticalProgressBar;
