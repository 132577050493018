import Grid from "@material-ui/core/Grid";
import { Link } from "gatsby";
import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "../../../assets/Images/Homepage/search_digi.svg";
import CloseIcon from "../../../assets/Images/Homepage/close_x.svg";
import SendIcon from "../../../assets/Images/Homepage/send_black.svg";
import DigiryteLogo from "../../../assets/Images/Homepage/Digiryte-logo.svg";
import Button from "../../../components/Button";
import DrawerToggleButton from "../SideDrawer/DrawerToggleButton";
import SearchResultsPopover from "../../../templates/Search";
import "./Toolbar.scss";
import { useContactModal } from "../../../components/Modal/contactModalContext";
import RightArrowWhite from "../../../assets/Icons/accordian_white.svg";
import RightArrow from "../../../assets/Icons/go.svg";

// import Grid from "@material-ui/core/Grid";

const Toolbar = (props) => {
  const [isSearch, setIsSearch] = useState(false);
  const { setOpenModal } = useContactModal(); // Use context

  // const toggleSearch = () => {
  //   setIsSearch(!isSearch);
  // };
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchValue, handleSearchValue] = useState(null);
  const searchInputRef = React.createRef();
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [hoverState1, sethoverState1] = useState(false);
  const [hoverState2, sethoverState2] = useState(false);
  const [isHover, setIsHover] = useState(false);

  // const handleMenu = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleOpen = (event) => {
    setAnchorEl(searchInputRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "search-results-popover" : undefined;
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    handleOpen();
    // SearchClient.getSearchResults(searchValue).then((res)=>{
    //     if(res.success){
    //         setSearchResult(res.result);
    //     }
    //     setLoading(false);
    // }).catch((e)=>{
    //     setLoading(false);
    // })
  };
  const toggleHover = () => {
    setIsHover(!isHover);
    sethoverState2(false);
    sethoverState1(false);
  };
  const ShowButtonComponent = ({}) => {
    return (
      <div>
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={!isHover ? RightArrow : RightArrowWhite}
              alt="Contact us"
            />
          }
          customClassName="info-view-resize-btn"
          color="primary"
          variant="outlined"
          label="Contact us"
          onClick={() => setOpenModal(true)}
          tip="Contact us"
          tooltip="yes"
          // onMouseEnter={toggleHover}
          // onMouseLeave={toggleHover}
        />
      </div>
    );
  };
  const DropDownArrow = ({ state }) => {
    return (
      <>
        <span style={{ display: "flex", marginLeft: 5 }}>
          {state ? (
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.2531 10L8.58641 5.33333L3.91974 10"
                stroke="#233642"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.2531 6.5L8.58647 11.1667L3.9198 6.5"
                stroke="#233642"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </span>
      </>
    );
  };
  return (
    <header
      id="header"
      className={`toolbar ${props.isLandingPage ? "landing-page-nav" : ""}`}
    >
      <nav className="toolbar__navigation body-container">
        <Grid container>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <div className="toolbar_navigation-items">
                <div>
                  {props.isLandingPage ? (
                    <div>
                      <img
                        className="toolbar__logo"
                        src={DigiryteLogo}
                        alt="Digiryte"
                      />
                    </div>
                  ) : (
                    <Link activeClassName="active" to="/">
                      <img
                        className="toolbar__logo"
                        src={DigiryteLogo}
                        alt="Digiryte"
                      />
                    </Link>
                  )}
                </div>
                {!props.isLandingPage && (
                  <ul>
                    <li>
                      <Link
                        activeStyle={{ color: "#db4435" }}
                        partiallyActive={true}
                        to="/our-pod-model/"
                        onMouseEnter={() => {
                          sethoverState2(false);
                          sethoverState1(false);
                        }}
                      >
                        Our Pod Model
                      </Link>
                    </li>
                    <li
                      className={`currentLink ${
                        hoverState2 ? "link-active" : ""
                      }`}
                    >
                      <Link
                        activeStyle={{ color: "#db4435" }}
                        partiallyActive={true}
                        to="/what-we-do"
                        onMouseEnter={() => {
                          sethoverState2(true);
                          sethoverState1(false);
                        }}
                      >
                        What we do <DropDownArrow state={hoverState2} />
                      </Link>
                    </li>
                    <li>
                      <Link
                        activeStyle={{ color: "#db4435" }}
                        // partiallyActive={true}
                        to="/who-we-work-with"
                        onMouseEnter={() => {
                          sethoverState2(false);
                          sethoverState1(false);
                        }}
                      >
                        Who we work with
                      </Link>
                    </li>

                    <li
                      className={`currentLink ${
                        hoverState1 ? "link-active" : ""
                      }`}
                    >
                      <Link
                        onMouseEnter={() => {
                          sethoverState1(true);
                          sethoverState2(false);
                        }}
                        onMouseLeave={() => {
                          //  sethoverState1(false);
                          //  sethoverState2(false);
                          //  sethoverState3(false);
                        }}
                        activeStyle={{ color: "#db4435" }}
                        // partiallyActive={true}
                        to="/insights"
                      >
                        Insights <DropDownArrow state={hoverState1} />
                      </Link>
                    </li>
                    {/* HIDING THIS TEMPORARILY FOR FUTURE RELEASE */}
                    <li>
                      <Link
                        activeStyle={{ color: "#db4435" }}
                        partiallyActive={true}
                        to="/about-us"
                        onMouseEnter={() => {
                          sethoverState2(false);
                          sethoverState1(false);
                        }}
                      >
                        About Us
                      </Link>
                    </li>
                  </ul>
                )}
                <ShowButtonComponent />
              </div>
              <div className="toolbar__toggle-button">
                {props.isLandingPage ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <img
                        src={DigiryteLogo}
                        alt="Digiryte"
                        className="lp-logo"
                      />
                    </div>
                    <ShowButtonComponent />
                  </div>
                ) : (
                  <DrawerToggleButton click={props.drawerClickHandler} />
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </nav>
      {hoverState1 === true && (
        <div style={{ position: "relative" }} className="">
          <div
            onMouseLeave={() => {
              sethoverState1(false);
              sethoverState2(false);
            }}
            onMouseEnter={() => {
              sethoverState1(true);
              sethoverState2(false);
            }}
            className="insights-hover-bg"
          >
            <Grid
              className="hover-container"
              justify="start"
              container
              direction="row"
              // spacing={3}
            >
              {/* <Grid item xs={12} sm={12} md={6}> */}
              <ul className="list-style" style={{ marginLeft: 0 }}>
                <li style={{ lineHeight: "40px", fontSize: "17px" }}>
                  <Link
                    activeStyle={{ color: "#db4435" }}
                    partiallyActive={true}
                    to="/insights/articles"
                    className="services-link1 "
                  >
                    <span className="services11">Articles</span>
                  </Link>
                </li>
                {/* </Grid> */}
                {/* <Grid item xs={12} sm={12} md={6}> */}
                <li style={{ lineHeight: "40px", fontSize: "17px" }}>
                  <Link
                    activeStyle={{ color: "#db4435" }}
                    partiallyActive={true}
                    to="/insights/white-papers"
                    className="services-link1 "
                  >
                    <span className="services11">White Papers</span>
                  </Link>
                </li>
              </ul>
            </Grid>
          </div>
        </div>
      )}
      {hoverState2 === true && (
        <div style={{ position: "relative" }} className="">
          <div
            onMouseLeave={() => {
              sethoverState1(false);
              sethoverState2(false);
            }}
            onMouseEnter={() => {
              sethoverState1(false);
              sethoverState2(true);
            }}
            className="whatWeDo-hover-bg"
          >
            <Grid
              className="hover-container"
              justify="start"
              container
              direction="row"
            >
              <Grid item md={6}>
                <h3 className="expand-nav-title">Engagement Models</h3>
                <div className="list-left-item">
                  <Link
                    activeStyle={{ color: "#db4435" }}
                    partiallyActive={true}
                    to="/team-augmentation"
                    className="services-link1 "
                  >
                    <span className="services11">
                      {" "}
                      <span
                        className="services11"
                        style={{ fontWeight: "bold", fontSize: "16px" }}
                      >
                        Team Augmentation{" "}
                      </span>
                      <br />
                      Highly skilled resources augmented into your existing
                      in-house team to help increase the velocity, frequency and
                      quality of your software delivery.
                    </span>{" "}
                  </Link>{" "}
                </div>
                <div className="list-left-item">
                  <Link
                    activeStyle={{ color: "#db4435" }}
                    partiallyActive={true}
                    to="/digiryte-pods"
                    className="services-link1 "
                  >
                    <span className="services11">
                      {" "}
                      <span
                        className="services11"
                        style={{ fontWeight: "bold", fontSize: "16px" }}
                      >
                        Agile Pod
                      </span>{" "}
                      <br />
                      Create a dedicated delivery capability consisting of
                      cross-functional resources to become an extension of your
                      in-house delivery teams.
                    </span>
                  </Link>
                </div>
                <div className="list-left-item">
                  <Link
                    activeStyle={{ color: "#db4435" }}
                    partiallyActive={true}
                    to="/product-development"
                    className="services-link1 "
                  >
                    <span className="services11">
                      {" "}
                      <span
                        style={{ fontWeight: "bold", fontSize: "16px" }}
                        className="services11"
                      >
                        Product Development{" "}
                      </span>{" "}
                      <br />
                      Providing expert engineers to help design, develop and
                      deliver quality software products that your customers love
                      using.{" "}
                    </span>
                  </Link>
                </div>
              </Grid>

              <Grid item md={6}>
                <h3 className="expand-nav-title custom-title">Expertise</h3>

                <ul className="list-style">
                  <li style={{ lineHeight: "40px", fontSize: "15px" }}>
                    <Link
                      activeStyle={{ color: "#db4435" }}
                      partiallyActive={true}
                      to="/mvp-development"
                      className="services-link1 "
                    >
                      <span className="services11"> MVP Development </span>
                    </Link>
                  </li>{" "}
                  <li style={{ lineHeight: "40px", fontSize: "15px" }}>
                    <Link
                      activeStyle={{ color: "#db4435" }}
                      partiallyActive={true}
                      to="/data-engineering"
                      className="services-link1 "
                    >
                      <span className="services11">Data Engineering </span>
                    </Link>
                  </li>{" "}
                  <li style={{ lineHeight: "40px", fontSize: "15px" }}>
                    <Link
                      activeStyle={{ color: "#db4435" }}
                      partiallyActive={true}
                      to="/machine-learning"
                      className="services-link1 "
                    >
                      <span className="services11"> AI / ML </span>
                    </Link>
                  </li>
                  <li style={{ lineHeight: "40px", fontSize: "15px" }}>
                    <Link
                      activeStyle={{ color: "#db4435" }}
                      partiallyActive={true}
                      to="/product-scaling"
                      className="services-link1 "
                    >
                      <span className="services11"> Product Scaling </span>
                    </Link>
                  </li>
                  <li style={{ lineHeight: "40px", fontSize: "15px" }}>
                    <Link
                      activeStyle={{ color: "#db4435" }}
                      partiallyActive={true}
                      to="/mobile-application-development"
                      className="services-link1 "
                    >
                      <span className="services11">
                        Mobile Application Development{" "}
                      </span>
                    </Link>
                  </li>
                  {/* </Grid> */}
                  {/* <Grid item xs={12} sm={12} md={6}> */}
                  <li style={{ lineHeight: "40px", fontSize: "15px" }}>
                    <Link
                      activeStyle={{ color: "#db4435" }}
                      partiallyActive={true}
                      to="/web-application-development"
                      className="services-link1 "
                    >
                      <span className="services11">
                        {" "}
                        Web Application Development{" "}
                      </span>
                    </Link>
                  </li>
                  <li style={{ lineHeight: "40px", fontSize: "15px" }}>
                    <Link
                      activeStyle={{ color: "#db4435" }}
                      partiallyActive={true}
                      to="/full-product-development"
                      className="services-link1 "
                    >
                      <span className="services11">
                        {" "}
                        Full Product Development{" "}
                      </span>
                    </Link>
                  </li>
                  <li style={{ lineHeight: "40px", fontSize: "15px" }}>
                    <Link
                      activeStyle={{ color: "#db4435" }}
                      partiallyActive={true}
                      to="/product-designing-and-prototyping"
                      className="services-link1 "
                    >
                      <span className="services11">
                        {" "}
                        Product Designing & Prototyping{" "}
                      </span>
                    </Link>
                  </li>
                  <li style={{ lineHeight: "40px", fontSize: "15px" }}>
                    <Link
                      activeStyle={{ color: "#db4435" }}
                      partiallyActive={true}
                      to="/code-ux-audit"
                      className="services-link1 "
                    >
                      <span className="services11"> Code & UX Audit </span>
                    </Link>
                  </li>
                  <li style={{ lineHeight: "40px", fontSize: "15px" }}>
                    <Link
                      activeStyle={{ color: "#db4435" }}
                      partiallyActive={true}
                      to="/digital-transformation"
                      className="services-link1 "
                    >
                      <span className="services11">
                        {" "}
                        Digital Transformation{" "}
                      </span>
                    </Link>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
      {isSearch === true && (
        <div>
          <form onSubmit={handleSubmit}>
            <Paper className="search-style" elevation={0}>
              <IconButton size="small" aria-label="close" color="inherit">
                <img src={SearchIcon} />
              </IconButton>
              {/* <SearchIcon style={{paddingTop:'10px'}} fontSize="default" /> */}
              <InputBase
                className="input-search"
                placeholder="Search..."
                ref={searchInputRef}
                onChange={(e) => handleSearchValue(e.target.value)}
                inputProps={{ "aria-label": "Search", "aria-describedby": id }}
              />
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                edge="end"
              >
                <img src={CloseIcon} />
              </IconButton>
              {/* <IconButton
              size="small"
              aria-label="close"
             
              edge="end"
            > */}
              <Button
                icon={<img src={SendIcon} alt="click" />}
                customClassName="effect-btn1"
                color="primary"
                label={props.displayFIndoutMore === "no" ? "" : ""}
                routepath="/search"
                view="show"
              />
              {/* <img style={{ color: green[500] }} src={SendIcon}/>
            </IconButton> */}
            </Paper>
          </form>
          <div>
            <SearchResultsPopover
              id={id}
              handleClose={handleClose}
              anchorEl={anchorEl}
              searchResult={searchResult}
            />
          </div>
        </div>
      )}
    </header>
  );
};

export default Toolbar;
